import React from "react";
import { Typography, Container } from "@mui/material";

function PatchNotes() {
    const nestedBullets = [
        "Major Updates",
        [
            "•Added Timesheet interface for Service and Systems Integration users",
            "•Added Device Lookup Page",
            "•Added Master Device Sheet for the configuration management info",
        ],
        "Additional Updates",
        [
            "•Ability to add/edit the network data",
            "•Master Device Sheet Notes section has ability to mark notes as 'complete'",
            "•Users can show all notes or view only the notes that are not yet 'complete'",
            "•Ability to add/edit Programmer, Install Tester, and Timer Test",
            "•Ability to add/edit the airgas vendor data",
            "•Templates use the product type and riser count to automatically pre-populate the device configuration information where possible",
            "•Ability to add/edit/remove additional risers, configuration information, and entire device components",
            "•Ability to clock in and out, tracking hours worked in real time",
            "•Ability to track and edit worked hours on a weekly basis",
            "•Ability to submit electronic PTO requests, track PTO approval status, and remaining PTO",
            "•Managers can review and approve/deny PTO requests and Timesheet submissions",
            "•Payroll Processors can review all employee timesheets, PTO requests, and PTO banks"
        ],
        "Changes",
        [
            "•The document file upload selection functionality has been updated to include additional file types"
        ],
    ];

    return (
        <>
            <Typography variant="h3" textAlign={"center"} style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                🔥 Release Notes 11/14/24 🔥
            </Typography>
            <ListComponent items={nestedBullets} />
        </>
    );
  }

  const ListComponent = ({ items }) => (
      <Container maxWidth="md" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
          <Typography component="ul" style={{ listStyleType: "none", paddingLeft: 0 }}>
              {
                  items.map((item, index) =>
                      (typeof item === "string") ? (
                          <Typography key={index} component="li" style={{ marginBottom: "10px" }}>
                              {item}
                          </Typography>
                      ) : React.isValidElement(item) && item.type === "img" ? (
                          <div key={index} style={{ marginBottom: "20px" }}>
                              {item}
                          </div>
                      ) : (
                          <ListComponent key={index} items={item} />
                      )
                  )
              }
          </Typography>
      </Container>
  );

  export default PatchNotes;

